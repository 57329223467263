/* eslint-disable no-async-promise-executor */
import axios from '../plugins/axios';

import {
  clearLocalStorage,
  setLocalStorage,
  getRequestHeaders,
  clearTokenInLocalStorage,
} from '../utils/helpers';

export function apiGetUserMe() {
  return new Promise(async (resolve, reject) => {
    try {
      // const response = await axios({
      //   method: 'GET',
      //   url: `/auth/profile`,
      // });
      const app_permission = await axios({
        method: 'GET',
        url: '/profile',
      });
      if (app_permission.status === 200) {
        setLocalStorage({
          key: 'cws-usr',
          value: JSON.stringify(app_permission.data.data),
        });
        setLocalStorage({
          key: 'tms-allow',
          value: true,
        });
      } else {
        clearTokenInLocalStorage();
        clearLocalStorage({ key: 'cws-usr' });
        clearLocalStorage({ key: 'tms-allow' });
      }
      resolve(app_permission);
    } catch (error) {
      clearTokenInLocalStorage();
      clearLocalStorage({ key: 'cws-usr' });
      clearLocalStorage({ key: 'tms-allow' });
      reject(error);
    }
  });
}

export function apiGetProfile() {
  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_AUTH_URL}/profile`,
    }).then((res) => {
      if (res.status === 200) resolve(res.data);
    }).catch((err) => {
      reject(err);
    });
  });
}

export function apiUpdateProfile(data) {
  return new Promise(async (resolve, reject) => {
    axios({
      method: 'PUT',
      url: `${process.env.REACT_APP_AUTH_URL}/profile`,
      data,
    }).then((res) => {
      if (res.status !== 200) reject(res.response);
      else resolve(res);
    }).catch((error) => {
      reject(error);
    });
  });
}

export function apiGetForgotPassword(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios({
        method: 'POST',
        url: '/auth/forgot-password',
        headers: getRequestHeaders({ withToken: false }),
        data: {
          username: payload.username,
        },
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiNewPassword(data) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios({
        method: 'POST',
        url: '/auth/reset-password',
        headers: getRequestHeaders({ withToken: false }),
        data,
      });
      if (response.status !== 200) {
        reject(response.response.data);
      }
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiRegister(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios({
        method: 'POST',
        url: '/auth/register',
        headers: getRequestHeaders({ withToken: false }),
        data: payload,
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
