/* eslint-disable no-async-promise-executor */
import axios from '../plugins/axios';
import { codeStatus, apiEndpoint } from '../utils/constant';

export function apiGetLegislator(props) {
  return new Promise(async (resolve, reject) => {
    const url = apiEndpoint.LEGISLATOR_DATA;
    try {
      const response = await axios({
        method: 'GET',
        url,
        params: {
          page: 1,
          limit: 10,
          ...props
        }
      });
      if (response.status !== codeStatus.SUCCESS) {
        reject(response.response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiGetLegislatorById(id) {
  return new Promise(async (resolve, reject) => {
    const url = `${apiEndpoint.LEGISLATOR_DATA}/${id}`;
    try {
      const response = await axios({
        method: 'GET',
        url,
        params: {
          page: 1,
          limit: 10
        }
      });
      if (response.status !== codeStatus.SUCCESS) {
        reject(response.response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
