export const actions = {
  GET_CITY_REQUEST: 'GET_CITY_REQUEST',
  GET_CITY_SUCCESS: 'GET_CITY_SUCCESS',
  GET_CITY_ERROR: 'GET_CITY_ERROR',

  GET_CITY_DOM_REQUEST: 'GET_CITY_DOM_REQUEST',
  GET_CITY_DOM_SUCCESS: 'GET_CITY_DOM_SUCCESS',
  GET_CITY_DOM_ERROR: 'GET_CITY_DOM_ERROR',

  getAllCity: (data) => {
    return {
      type: actions.GET_CITY_REQUEST,
      data,
    };
  },
  getAllCityDom: (data) => {
    return {
      type: actions.GET_CITY_DOM_REQUEST,
      data,
    };
  },
};

const initState = {
  isLoading: false,
  list: [],
  error: null,
  metaData: {
    count: 0,
  },
  isLoadingDom: false,
  listDom: [],
  errorDom: null,
  metaDataDom: {
    count: 0,
  },
};

export default function userReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.GET_CITY_REQUEST:
      return {
        ...state, isLoading: true, error: null,
      };
    case actions.GET_CITY_SUCCESS:
      return {
        ...state, ...payload, isLoading: false, error: null,
      };
    case actions.GET_CITY_ERROR:
      return {
        ...state, isLoading: false, list: [], ...payload,
      };

    case actions.GET_CITY_DOM_REQUEST:
      return {
        ...state, isLoadingDom: true, errorDom: null,
      };
    case actions.GET_CITY_DOM_SUCCESS:
      return {
        ...state, ...payload, isLoadingDom: false, errorDom: null,
      };
    case actions.GET_CITY_DOM_ERROR:
      return {
        ...state, isLoadingDom: false, listDom: [], ...payload,
      };

    default:
      return state;
  }
}
