export const actions = {
  AUTH_CHECK: 'AUTH_CHECK',
  AUTH_LOGOUT: 'AUTH_LOGOUT',

  AUTH_LOGIN_REQUEST: 'AUTH_LOGIN_REQUEST',
  AUTH_LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
  AUTH_LOGIN_ERROR: 'AUTH_LOGIN_ERROR',

  AUTH_USER_REQUEST: 'AUTH_USER_REQUEST',
  AUTH_USER_SUCCESS: 'AUTH_USER_SUCCESS',
  AUTH_USER_ERROR: 'AUTH_USER_ERROR',

  checkAuthorization: () => ({ type: actions.AUTH_CHECK }),
  login: (props = { username: '', password: '' }) => {
    return {
      type: actions.AUTH_LOGIN_REQUEST,
      payload: props,
    };
  },
  logout: () => ({
    type: actions.AUTH_LOGOUT,
  }),
};

const initState = {
  isLoadingAuth: false,
  errorAuth: {
    message: '',
  },
  user: {},
  token: '',
  refresh_token: '',
  refreshTokenExpire: '',
};

export default function authReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.AUTH_LOGOUT:
      return initState;

    case actions.AUTH_LOGIN_REQUEST:
      return { ...state, isLoadingAuth: true };
    case actions.AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        ...payload,
        isLoadingAuth: false,
        errorAuth: {
          message: '',
        },
      };
    case actions.AUTH_LOGIN_ERROR:
      return { ...state, isLoadingAuth: false, ...payload };

    case actions.AUTH_USER_REQUEST:
      return { ...state, isLoadingAuth: true };
    case actions.AUTH_USER_SUCCESS:
      return {
        ...state,
        isLoadingAuth: false,
        ...payload,
        errorAuth: {
          message: '',
        },
      };
    case actions.AUTH_USER_ERROR:
      return { ...state, isLoadingAuth: false, ...payload };

    default:
      return state;
  }
}
