import axios from '../plugins/axios';

const test = () => {
  return null;
};

const apiGetDistrict = (payload) => {
  return new Promise((resolve, reject) => {
    let url = `${process.env.REACT_APP_AUTH_URL}/district?`;
    if (payload.cityId) url += `&city_id=${payload.cityId}`;
    if (payload.cityCode) url += `&city_code=${payload.cityCode}`;
    if (payload.provinceCode) url += `&province_code=${payload.provinceCode}`;
    axios({
      method: 'GET',
      url,
    }).then((res) => {
      if (res.status !== 200) reject(res.response.data);
      else resolve(res.data);
    }).catch((error) => {
      reject(error);
    });
  });
};

export {
  test,
  apiGetDistrict,
};
