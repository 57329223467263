export const actions = {
  GET_ALL_POKIR_REQUEST: 'GET_ALL_POKIR_REQUEST',
  GET_ALL_POKIR_SUCCESS: 'GET_ALL_POKIR_SUCCESS',
  GET_ALL_POKIR_ERROR: 'GET_ALL_POKIR_ERROR',
  GET_ALL_POKIR_RESET: 'GET_ALL_POKIR_RESET',

  GET_POKIR_DETAIL_REQUEST: 'GET_POKIR_DETAIL_REQUEST',
  GET_POKIR_DETAIL_SUCCESS: 'GET_POKIR_DETAIL_SUCCESS',
  GET_POKIR_DETAIL_ERROR: 'GET_POKIR_DETAIL_ERROR',
  GET_POKIR_DETAIL_RESET: 'GET_POKIR_DETAIL_RESET',

  getAllPokir: (data) => {
    return {
      type: actions.GET_ALL_POKIR_REQUEST,
      data,
    };
  },
  getPokirById: (id) => {
    return {
      type: actions.GET_POKIR_DETAIL_REQUEST,
      id
    };
  },
  reset: () => {
    return {
      type: actions.GET_ALL_POKIR_RESET,
    };
  },
  resetDetail: () => {
    return {
      type: actions.GET_POKIR_DETAIL_RESET,
    };
  },
};

const initState = {
  isLoading: false,
  list: [],
  error: null,
  metaData: {
    count: 0,
    page: 1,
    limit: 10,
  },

  pokirDetailLoading: false,
  pokirDetail: null,
  pokirDetailError: null,
};

export default function userReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.GET_ALL_POKIR_REQUEST:
      return {
        ...state, isLoading: true, error: null,
      };
    case actions.GET_ALL_POKIR_SUCCESS:
      return {
        ...state, ...payload, isLoading: false, error: null,
      };
    case actions.GET_ALL_POKIR_ERROR:
      return {
        ...state, isLoading: false, list: [], ...payload,
      };
    case actions.GET_ALL_POKIR_RESET:
      return {
        ...state,
        isLoading: false,
        metaData: null,
        error: null,
        limit: 10,
        ...payload,
      };
    case actions.GET_POKIR_DETAIL_REQUEST:
      return {
        ...state, pokirDetailLoading: true, pokirDetailError: null, pokirDetail: null,
      };
    case actions.GET_POKIR_DETAIL_SUCCESS:
      return {
        ...state, ...payload, pokirDetailLoading: false, pokirDetailError: null,
      };
    case actions.GET_POKIR_DETAIL_ERROR:
      return {
        ...state, pokirDetailLoading: false, pokirDetail: null, ...payload,
      };
    default:
      return state;
  }
}
