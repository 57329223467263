import axios from '../plugins/axios';
import { getLegislatorId } from '../utils/helpers';

const apiGetAllRating = () => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_AUTH_URL}/ratings?legislator_id=${getLegislatorId()}`;
    axios({
      method: 'GET',
      url,
    }).then((res) => {
      if (res.status !== 200) reject(res.response.data);
      else resolve(res.data);
    }).catch((error) => reject(error));
  });
};

const test = () => {
  return null;
};

export {
  apiGetAllRating,
  test,
};
