export const actions = {
  GET_ALL_LAPORAN_REQUEST: 'GET_ALL_LAPORAN_REQUEST',
  GET_ALL_LAPORAN_SUCCESS: 'GET_ALL_LAPORAN_SUCCESS',
  GET_ALL_LAPORAN_ERROR: 'GET_ALL_LAPORAN_ERROR',
  GET_ALL_LAPORAN_RESET: 'GET_ALL_LAPORAN_RESET',

  GET_LAPORAN_DETAIL_REQUEST: 'GET_LAPORAN_DETAIL_REQUEST',
  GET_LAPORAN_DETAIL_SUCCESS: 'GET_LAPORAN_DETAIL_SUCCESS',
  GET_LAPORAN_DETAIL_ERROR: 'GET_LAPORAN_DETAIL_ERROR',
  GET_LAPORAN_DETAIL_RESET: 'GET_LAPORAN_DETAIL_RESET',

  getAllLaporan: (data) => {
    return {
      type: actions.GET_ALL_LAPORAN_REQUEST,
      data,
    };
  },
  getLaporanById: (id) => {
    return {
      type: actions.GET_LAPORAN_DETAIL_REQUEST,
      id
    };
  },
  reset: () => {
    return {
      type: actions.GET_ALL_LAPORAN_RESET,
    };
  },
  resetDetail: () => {
    return {
      type: actions.GET_LAPORAN_DETAIL_RESET,
    };
  },
};

const initState = {
  isLoading: false,
  list: [],
  error: null,
  metaData: {
    count: 0,
    page: 1,
    limit: 10,
  },

  laporanDetailLoading: false,
  laporanDetail: null,
  laporanDetailError: null,
};

export default function userReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.GET_ALL_LAPORAN_REQUEST:
      return {
        ...state, isLoading: true, error: null,
      };
    case actions.GET_ALL_LAPORAN_SUCCESS:
      return {
        ...state, ...payload, isLoading: false, error: null,
      };
    case actions.GET_ALL_LAPORAN_ERROR:
      return {
        ...state, isLoading: false, list: [], ...payload,
      };
    case actions.GET_ALL_LAPORAN_RESET:
      return {
        ...state,
        isLoading: false,
        metaData: null,
        error: null,
        limit: 10,
        ...payload,
      };
    case actions.GET_LAPORAN_DETAIL_REQUEST:
      return {
        ...state, laporanDetailLoading: true, laporanDetailError: null, laporanDetail: null,
      };
    case actions.GET_LAPORAN_DETAIL_SUCCESS:
      return {
        ...state, ...payload, laporanDetailLoading: false, laporanDetailError: null,
      };
    case actions.GET_LAPORAN_DETAIL_ERROR:
      return {
        ...state, laporanDetailLoading: false, laporanDetail: null, ...payload,
      };
    default:
      return state;
  }
}
