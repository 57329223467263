import axios from '../plugins/axios';
import { getLegislatorId } from '../utils/helpers';

const apiGetAllPokir = (payload) => {
  return new Promise((resolve, reject) => {
    let url = `${process.env.REACT_APP_AUTH_URL}/pokir?legislator_id=${getLegislatorId()}&limit=${payload.limit}&page=${payload.page}`;
    if (payload.universal && payload.universalValue) url += `&${payload.universal}=${payload.universalValue}`;
    axios({
      method: 'GET',
      url,
    }).then((res) => {
      if (res.status !== 200) reject(res.response.data);
      else resolve(res.data);
    }).catch((error) => reject(error));
  });
};

const apiGetPokirById = (id) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_AUTH_URL}/pokir/${id}`,
    }).then((res) => {
      if (res.status !== 200) reject(res.response.data);
      else resolve(res.data);
    }).catch((error) => {
      reject(error);
    });
  });
};

const apiCreatePokir = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_AUTH_URL}/pokir`,
      data,
    }).then((res) => {
      if (res.status !== 200) reject(res.response);
      else resolve(res);
    }).catch((error) => {
      reject(error);
    });
  });
};

const apiEditPokir = (data, id) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'Put',
      url: `${process.env.REACT_APP_AUTH_URL}/pokir/${id}`,
      data,
    }).then((res) => {
      if (res.status !== 200) reject(res.response);
      else resolve(res);
    }).catch((error) => {
      reject(error);
    });
  });
};

const apiUpdatePokir = (data, id) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_AUTH_URL}/pokir/${id}/thread`,
      data,
    }).then((res) => {
      if (res.status !== 200) reject(res.response);
      else resolve(res);
    }).catch((error) => {
      reject(error);
    });
  });
};

const apiDeletePokirById = (id) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'DELETE',
      url: `${process.env.REACT_APP_AUTH_URL}/pokir/${id}`,
    }).then((res) => {
      if (res.status !== 200) reject(res.response.data);
      else resolve(res.data);
    }).catch((error) => {
      reject(error);
    });
  });
};

export {
  apiGetAllPokir,
  apiGetPokirById,
  apiCreatePokir,
  apiEditPokir,
  apiUpdatePokir,
  apiDeletePokirById
};
