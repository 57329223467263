/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Layout, Button } from 'antd';
import { actions as appActions } from '../../redux/app';
import TopbarUser from './topbarUser';
import TopbarWrapper from './topbar.style';
import themes from '../../settings/themes';
import { themeConfig } from '../../settings';

const { Header } = Layout;
const { toggleCollapsed } = appActions;
const customizedTheme = themes[themeConfig.theme];

class Topbar extends Component {
  render() {
    const {
      toggleCollapsed,
      displayButton,
      collapsed,
      openDrawer,
    } = this.props;
    const styling = {
      background: customizedTheme.backgroundColor,
      position: 'fixed',
      width: '100%',
      height: 70,
    };
    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={
            (collapsed && !openDrawer) ? 'isomorphicTopbar collapsed' : 'isomorphicTopbar'
          }
        >
          <div className="isoLeft">
            {
              !displayButton && (

                <button
                  className={(collapsed && !openDrawer)
                    ? ('triggerBtn menuCollapsed') : ('triggerBtn menuOpen')}
                  type="button"
                  style={{ color: customizedTheme.textColor }}
                  onClick={toggleCollapsed}
                />
              )
            }
            <Link to="/dashboard">
              <Button
                icon="home"
                className="btn-psi-block"
                type="primary"
              >
                Home
              </Button>
            </Link>
          </div>

          <ul className="isoRight">
            <li
              className="isoUser"
            >
              <TopbarUser />
            </li>
          </ul>
        </Header>
      </TopbarWrapper>
    );
  }
}

export default connect(
  (state) => ({
    ...state.app,
  }),
  { toggleCollapsed },
)(Topbar);
