import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';

const routes = [
  {
    path: '',
    component: asyncComponent(() => import('../dashboard')),
  },
  {
    path: 'blankPage',
    component: asyncComponent(() => import('../blankPage')),
  },
  {
    path: 'authCheck',
    component: asyncComponent(() => import('../AuthCheck')),
  },
  {
    path: 'profile',
    component: asyncComponent(() => import('../account/profile/profile')),
  },
  {
    path: 'agenda',
    component: asyncComponent(() => import('../agenda/List')),
  },
  {
    path: 'agenda/create',
    component: asyncComponent(() => import('../agenda/Create')),
  },
  {
    path: 'agenda/update/:agendaId',
    component: asyncComponent(() => import('../agenda/Update')),
  },
  {
    path: 'listRaperdaRapdbd',
    component: asyncComponent(() => import('../raperda/List')),
  },
  {
    path: 'rapbd/:rapbdId',
    component: asyncComponent(() => import('../raperda/Detail')),
  },
  {
    path: 'listPokir',
    component: asyncComponent(() => import('../pokir/List')),
  },
  {
    path: 'createPokir',
    component: asyncComponent(() => import('../pokir/Create')),
  },
  {
    path: 'pokir/:pokirId',
    component: asyncComponent(() => import('../pokir/Detail')),
  },
  {
    path: 'createRapbd',
    component: asyncComponent(() => import('../raperda/Create')),
  },
  {
    path: 'updateRapbd/:rapbdId',
    component: asyncComponent(() => import('../raperda/Update')),
  },
  {
    path: 'listTickets',
    component: asyncComponent(() => import('../tickets/List')),
  },
  {
    path: 'ticket/:ticketId',
    component: asyncComponent(() => import('../tickets/Update')),
  },
  {
    path: 'listLaporan',
    component: asyncComponent(() => import('../laporan/List')),
  },
  {
    path: 'createLaporan',
    component: asyncComponent(() => import('../laporan/Create')),
  },
  {
    path: 'laporan/:laporanId',
    component: asyncComponent(() => import('../laporan/Detail')),
  },
  {
    path: 'listRating',
    component: asyncComponent(() => import('../rating/List')),
  },
  {
    path: 'legislatorProfile',
    component: asyncComponent(() => import('../legislatorProfile/index')),
  },
  {
    path: 'penugasan/:id',
    component: asyncComponent(() => import('../tugas/detail')),
  },
];

class AppRouter extends Component {
  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        <Switch>
          {routes.map((singleRoute) => {
            const { path, exact, ...otherProps } = singleRoute;
            return (
              <Route
                exact={exact !== false}
                key={singleRoute.path}
                path={`${url}/${singleRoute.path}`}
                {...otherProps}
              />
            );
          })}
        </Switch>
      </div>
    );
  }
}

export default AppRouter;
