import {
  all, takeEvery, put, fork, call
} from 'redux-saga/effects';
import { notification } from '../components';
import { actions } from '../redux/village';
import {
  apiGetVillage,
} from '../api/api-village';

export function* getAllVillage() {
  yield takeEvery(actions.GET_VILLAGE_REQUEST, function* (payload) {
    try {
      const response = yield call(apiGetVillage, payload.data);
      if (response) {
        yield put({
          type: actions.GET_VILLAGE_SUCCESS,
          payload: {
            list: response.data.rows,
            metaData: {
              count: response.data.count,
            }
          }
        });
      }
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }
      if (error.message) {
        notification('error', 'Something went wrong', error.message);
      } else {
        notification('error', 'Something went wrong', JSON.stringify(error));
      }
      yield put({
        type: actions.GET_VILLAGE_ERROR,
        payload: {
          errorAuth: errorResponse,
        },
      });
    }
  });
}

export function* getAllVillageDom() {
  yield takeEvery(actions.GET_VILLAGE_DOM_REQUEST, function* (payload) {
    try {
      const response = yield call(apiGetVillage, payload.data);
      if (response) {
        yield put({
          type: actions.GET_VILLAGE_DOM_SUCCESS,
          payload: {
            listDom: response.data.rows,
            metaDataDom: {
              count: response.data.count,
            }
          }
        });
      }
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }
      if (error.message) {
        notification('error', 'Something went wrong', error.message);
      } else {
        notification('error', 'Something went wrong', JSON.stringify(error));
      }
      yield put({
        type: actions.GET_VILLAGE_DOM_ERROR,
        payload: {
          errorAuth: errorResponse,
        },
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getAllVillage),
    fork(getAllVillageDom),
  ]);
}
