const options = [
  { // 1
    key: 'legislatorProfile',
    label: 'Profile',
    leftIcon: 'user',
  },
  { // 2
    key: 'raperda',
    label: 'RAPERDA + RAPBD',
    leftIcon: 'control',
    children: [
      {
        key: 'listRaperdaRapdbd',
        label: 'Daftar RAPERDA dan RAPBD',
      }
    ],
  },
  { // 3
    key: 'laporan',
    label: 'Laporan & Agenda',
    leftIcon: 'database',
    children: [
      {
        key: 'listLaporan',
        label: 'Daftar Laporan',
      },
      {
        key: 'agenda',
        label: 'Daftar Agenda',
      }
    ],
  },
  // { // 4
  //   key: 'pokir',
  //   label: 'POKIR',
  //   leftIcon: 'heat-map',
  //   children: [
  //     {
  //       key: 'listPokir',
  //       label: 'Daftar POKIR',
  //     }
  //   ],
  // },
  { // 5
    key: 'ticket',
    label: 'Aduan Masyarakat',
    leftIcon: 'area-chart',
    children: [
      {
        key: 'listTickets',
        label: 'Daftar Aduan Masyarakat',
      }
    ],
  },
  { // 6
    key: 'rating',
    label: 'Rating',
    leftIcon: 'star',
    children: [
      {
        key: 'listRating',
        label: 'Daftar Rating',
      }
    ],
  }
];
export default options;
