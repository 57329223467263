export const actions = {
  JADWAL_AGENDA_REQUEST: 'JADWAL_AGENDA_REQUEST',
  JADWAL_AGENDA_SUCCESS: 'JADWAL_AGENDA_SUCCESS',
  JADWAL_AGENDA_ERROR: 'JADWAL_AGENDA_ERROR',

  JADWAL_AGENDA_DETAIL_REQUEST: 'JADWAL_AGENDA_DETAIL_REQUEST',
  JADWAL_AGENDA_DETAIL_SUCCESS: 'JADWAL_AGENDA_DETAIL_SUCCESS',
  JADWAL_AGENDA_DETAIL_ERROR: 'JADWAL_AGENDA_DETAIL_ERROR',

  JADWAL_AGENDA_CREATE_REQUEST: 'JADWAL_AGENDA_CREATE_REQUEST',
  JADWAL_AGENDA_CREATE_SUCCESS: 'JADWAL_AGENDA_CREATE_SUCCESS',
  JADWAL_AGENDA_CREATE_ERROR: 'JADWAL_AGENDA_CREATE_ERROR',

  JADWAL_AGENDA_UPDATE_REQUEST: 'JADWAL_AGENDA_UPDATE_REQUEST',
  JADWAL_AGENDA_UPDATE_SUCCESS: 'JADWAL_AGENDA_UPDATE_SUCCESS',
  JADWAL_AGENDA_UPDATE_ERROR: 'JADWAL_AGENDA_UPDATE_ERROR',

  JADWAL_AGENDA_DELETE_REQUEST: 'JADWAL_AGENDA_DELETE_REQUEST',
  JADWAL_AGENDA_DELETE_SUCCESS: 'JADWAL_AGENDA_DELETE_SUCCESS',
  JADWAL_AGENDA_DELETE_ERROR: 'JADWAL_AGENDA_DELETE_ERROR',

  searchJadwalAgenda: (data) => {
    return {
      type: actions.JADWAL_AGENDA_REQUEST,
      data,
    };
  },

  getDetailAgendaById: (data) => {
    return {
      type: actions.JADWAL_AGENDA_DETAIL_REQUEST,
      data,
    };
  },

  createAgenda: (data) => {
    return {
      type: actions.JADWAL_AGENDA_CREATE_REQUEST,
      data,
    };
  },

  updateAgenda: (data) => {
    return {
      type: actions.JADWAL_AGENDA_UPDATE_REQUEST,
      data,
    };
  },

  deleteAgenda: (data) => {
    return {
      type: actions.JADWAL_AGENDA_DELETE_REQUEST,
      data,
    };
  }
};

const initState = {
  isLoading: false,
  list: [],
  error: null,
  detail: null,
  formLoading: false
};

export default function jadwalAgendaReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.JADWAL_AGENDA_REQUEST:
    case actions.JADWAL_AGENDA_DETAIL_REQUEST:
    case actions.JADWAL_AGENDA_DELETE_REQUEST:
      return {
        ...state, isLoading: true, type
      };
    case actions.JADWAL_AGENDA_CREATE_REQUEST:
    case actions.JADWAL_AGENDA_UPDATE_REQUEST:
      return {
        ...state, formLoading: true, type
      };
    case actions.JADWAL_AGENDA_SUCCESS:
    case actions.JADWAL_AGENDA_DETAIL_SUCCESS:
    case actions.JADWAL_AGENDA_DELETE_SUCCESS:
    case actions.JADWAL_AGENDA_CREATE_SUCCESS:
    case actions.JADWAL_AGENDA_UPDATE_SUCCESS:
      return {
        ...state, ...payload, isLoading: false, formLoading: false, type
      };
    case actions.JADWAL_AGENDA_ERROR:
    case actions.JADWAL_AGENDA_CREATE_ERROR:
    case actions.JADWAL_AGENDA_UPDATE_ERROR:
    case actions.JADWAL_AGENDA_DETAIL_ERROR:
    case actions.JADWAL_AGENDA_DELETE_ERROR:
      return {
        ...state, ...payload, isLoading: false, formLoading: false, detail: null, type
      };
    default:
      return state;
  }
}
