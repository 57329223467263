export const URL_ROOT = () => ('/');
export const URL_PROFILE = () => ('/dashboard/profile');
export const URL_DASHBOARD = () => ('/dashboard');

export const URL_LOGIN = () => ('/login');
export const URL_LOGOUT = () => ('/login');
export const URL_FORGOT_PASSWORD = () => ('/forgot-password');
export const URL_REGISTRATION = () => ('/registration');
export const URL_RESET_PASSWORD = () => ('/reset-password');
export const URL_403 = () => ('/403');
export const URL_404 = () => ('/404');
export const URL_NO_PERMISSION = () => ('/no-access');

export const URL_ACCOUNT = () => ('/account');
export const URL_ACCOUNT_CREATE = () => ('/account/create');
export const URL_ACCOUNT_MANAGEMENT = () => ('/account/management');
export const URL_ACCOUNT_MANAGEMENT_DETAIL = (id) => (`/account/management/${id}`);
export const URL_ACCOUNT_MENU = () => ('/account/menu');

export const URL_USER_LIST = () => ('/user');
export const URL_USER_DETAIL = (id) => (`/user/${id}`);
