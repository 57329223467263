import auth from './auth';
import app from './app';
import account from './account/forgotPass';
import rapbd from './rapbd';
import jadwalAgenda from './jadwalAgenda';
import legislator from './legislator';
import pokir from './pokir';
import province from './province';
import city from './city';
import village from './village';
import district from './district';
import tickets from './tickets';
import laporan from './laporan';
import rating from './rating';
import profile from './profile';

export default {
  auth,
  app,
  account,
  rapbd,
  jadwalAgenda,
  legislator,
  pokir,
  province,
  city,
  village,
  district,
  tickets,
  laporan,
  rating,
  profile
};
