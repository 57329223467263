/* eslint-disable no-async-promise-executor */
import axios from '../plugins/axios';
import { codeStatus, apiEndpoint } from '../utils/constant';
import { getLegislatorId } from '../utils/helpers';

export function apiGetJadwalAgenda(props) {
  return new Promise(async (resolve, reject) => {
    const url = apiEndpoint.JADWAL_AGENDA;
    props.legislator_id = getLegislatorId();
    try {
      const response = await axios({
        method: 'GET',
        url,
        params: props
      });

      if (response.status !== codeStatus.SUCCESS) {
        reject(response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiGetAgendaById(id) {
  return new Promise(async (resolve, reject) => {
    const url = `${apiEndpoint.JADWAL_AGENDA}/${id}`;
    try {
      const response = await axios({
        method: 'GET',
        url
      });

      if (response.status !== codeStatus.SUCCESS) {
        reject(response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiCreateAgenda(params) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios({
        method: 'POST',
        url: apiEndpoint.JADWAL_AGENDA,
        data: params
      });

      if (response.status !== codeStatus.SUCCESS) {
        reject(response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiUpdateAgenda(id, params) {
  return new Promise(async (resolve, reject) => {
    const url = `${apiEndpoint.JADWAL_AGENDA}/${id}`;
    try {
      const response = await axios({
        method: 'PUT',
        url,
        data: params
      });

      if (response.status !== codeStatus.SUCCESS) {
        reject(response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiDeleteAgenda(id, params) {
  return new Promise(async (resolve, reject) => {
    const url = `${apiEndpoint.JADWAL_AGENDA}/${id}`;
    try {
      const response = await axios({
        method: 'DELETE',
        url,
        data: params
      });

      if (response.status !== codeStatus.SUCCESS) {
        reject(response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
