/* eslint-disable no-async-promise-executor */
import axios from '../plugins/axios';
import {
  getRequestHeaders,
  setTokenInLocalStorage,
  getTokenInLocalStorage,
  clearTokenInLocalStorage,
} from '../utils/helpers';
import validator from './validator/api-auth';

export function apiPostLogin(props = {}) {
  return new Promise(async (resolve, reject) => {
    try {
      const defaultProps = {
        email: '',
        password: '',
      };
      const curProps = { ...defaultProps, ...props };
      const response = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_AUTH_URL}/auth/login`,
        data: curProps,
      });
      if (response.status === 200) {
        setTokenInLocalStorage(response.data.data);
        resolve(response.data.data);
      } else {
        clearTokenInLocalStorage();
        reject(response);
      }
    } catch (error) {
      clearTokenInLocalStorage();
      reject(error);
    }
  });
}

export function apiGetForgotPassword(props = {}) {
  return new Promise(async (resolve, reject) => {
    const defaultProps = {
      email: '',
    };
    const curProps = { ...defaultProps, ...props };
    try {
      await validator.apiPostForgotPassword(curProps);
      const response = axios({
        method: 'GET',
        url: `${process.env.REACT_APP_AUTH_URL}/auth/resetpwd/${curProps.email}`,
        headers: getRequestHeaders({ withToken: false }),
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiPostResetPassword(props = {}) {
  return new Promise(async (resolve, reject) => {
    // try {
    const defaultProps = {
      username: '',
      email: '',
      password: '',
      reset_code: '',
    };
    const curProps = { ...defaultProps, ...props };
    try {
      await validator.apiPostResetPassword(curProps);
      const response = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_AUTH_URL}/auth/resetpwd`,
        data: curProps,
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiPostRegister(props = {}) {
  const defaultProps = {
    name: '',
    email: '',
    password: '',
  };
  const curProps = { ...defaultProps, ...props };
  return new Promise(async (resolve, reject) => {
    try {
      await apiPostRegister(curProps);
      const response = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_AUTH_URL}/auth/register`,
        data: curProps,
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiPostRefreshToken() {
  return new Promise(async (resolve, reject) => {
    try {
      // validator.apiPostRefreshToken({ refresh_token: getTokenInLocalStorage().refresh_token });
      const response = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_AUTH_URL}/auth/refresh-token`,
        data: {
          refreshToken: getTokenInLocalStorage().refresh_token,
        },
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiPostUsers() {
  return axios({
    method: 'POST',
    url: `${process.env.REACT_APP_AUTH_URL}/auth/users`,
    headers: getRequestHeaders({ withToken: false }),
  });
}

export function apiChangePassword(data) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_AUTH_URL}/auth/change-password`,
        headers: getRequestHeaders({ withToken: false }),
        data,
      });
      if (response.status !== 200) {
        reject(response.response.data);
      } else {
        resolve(response);
      }
    } catch (error) {
      reject(error);
    }
  });
}
