export const apiEndpoint = {
  JADWAL_AGENDA: '/jadwal_agenda',
  LEGISLATOR_DATA: '/legislator_data'
};

export const codeStatus = {
  SUCCESS_0: 0,
  SUCCESS: 200,
  BAD_REQUEST: 400,
  INTERNAL_SERVER_ERROR: 500,
  UNATHORIZED: 401,
  FORBIDDEN: 403,
  SERVICE_NOT_FOUND: 404
};
