export const actions = {
  PROFILE_REQUEST: 'PROFILE_REQUEST',
  PROFILE_SUCCESS: 'PROFILE_SUCCESS',
  PROFILE_ERROR: 'PROFILE_ERROR',

  searchProfile: () => {
    return {
      type: actions.PROFILE_REQUEST,
    };
  },
};

const initState = {
  isLoading: false,
  success: null,
  error: null,
};

export default function userReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.PROFILE_REQUEST:
      return {
        ...state, isLoading: true, error: null,
      };
    case actions.PROFILE_SUCCESS:
      return {
        ...state, ...payload, isLoading: false, error: null,
      };
    case actions.PROFILE_ERROR:
      return {
        ...state, isLoading: false, success: null, ...payload,
      };
    default:
      return state;
  }
}
