export const actions = {
  GET_VILLAGE_REQUEST: 'GET_VILLAGE_REQUEST',
  GET_VILLAGE_SUCCESS: 'GET_VILLAGE_SUCCESS',
  GET_VILLAGE_ERROR: 'GET_VILLAGE_ERROR',

  GET_VILLAGE_DOM_REQUEST: 'GET_VILLAGE_DOM_REQUEST',
  GET_VILLAGE_DOM_SUCCESS: 'GET_VILLAGE_DOM_SUCCESS',
  GET_VILLAGE_DOM_ERROR: 'GET_VILLAGE_DOM_ERROR',

  getAllVillage: (data) => {
    return {
      type: actions.GET_VILLAGE_REQUEST,
      data,
    };
  },
  getAllVillageDom: (data) => {
    return {
      type: actions.GET_VILLAGE_DOM_REQUEST,
      data,
    };
  },
};

const initState = {
  isLoading: false,
  list: [],
  error: null,
  metaData: {
    count: 0,
  },
  isLoadingDom: false,
  listDom: [],
  errorDom: null,
  metaDataDom: {
    count: 0,
  },
};

export default function userReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.GET_VILLAGE_REQUEST:
      return {
        ...state, isLoading: true, error: null,
      };
    case actions.GET_VILLAGE_SUCCESS:
      return {
        ...state, ...payload, isLoading: false, error: null,
      };
    case actions.GET_VILLAGE_ERROR:
      return {
        ...state, isLoading: false, list: [], ...payload,
      };

    case actions.GET_VILLAGE_DOM_REQUEST:
      return {
        ...state, isLoadingDom: true, errorDom: null,
      };
    case actions.GET_VILLAGE_DOM_SUCCESS:
      return {
        ...state, ...payload, isLoadingDom: false, errorDom: null,
      };
    case actions.GET_VILLAGE_DOM_ERROR:
      return {
        ...state, isLoadingDom: false, listDom: [], ...payload,
      };

    default:
      return state;
  }
}
