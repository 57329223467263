import axios from '../plugins/axios';

const test = () => {
  return null;
};

const apiGetVillage = (payload) => {
  return new Promise((resolve, reject) => {
    let url = `${process.env.REACT_APP_AUTH_URL}/village?`;
    if (payload.districtId) url += `&district_id=${payload.districtId}`;
    if (payload.districtCode) url += `&district_code=${payload.districtCode}`;
    if (payload.cityCode) url += `&city_code=${payload.cityCode}`;
    if (payload.provinceCode) url += `&province_code=${payload.provinceCode}`;
    axios({
      method: 'GET',
      url,
    }).then((res) => {
      if (res.status !== 200) reject(res.response.data);
      else resolve(res.data);
    }).catch((error) => {
      reject(error);
    });
  });
};

export {
  test,
  apiGetVillage,
};
