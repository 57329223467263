export const actions = {
  GET_ALL_TICKETS_REQUEST: 'GET_ALL_TICKETS_REQUEST',
  GET_ALL_TICKETS_SUCCESS: 'GET_ALL_TICKETS_SUCCESS',
  GET_ALL_TICKETS_ERROR: 'GET_ALL_TICKETS_ERROR',
  GET_TICKET_DETAIL_REQUEST: 'GET_TICKET_DETAIL_REQUEST',
  GET_TICKET_DETAIL_SUCCESS: 'GET_TICKET_DETAIL_SUCCESS',
  GET_TICKET_DETAIL_ERROR: 'GET_TICKET_DETAIL_ERROR',
  CREATE_TICKET_REQUEST: 'CREATE_TICKET_REQUEST',
  CREATE_TICKET_SUCCESS: 'CREATE_TICKET_SUCCESS',
  CREATE_TICKET_ERROR: 'CREATE_TICKET_ERROR',
  UPDATE_TICKET_REQUEST: 'UPDATE_TICKET_REQUEST',
  UPDATE_TICKET_SUCCESS: 'UPDATE_TICKET_SUCCESS',
  UPDATE_TICKET_ERROR: 'UPDATE_TICKET_ERROR',
  REPLY_TICKET_REQUEST: 'REPLY_TICKET_REQUEST',
  REPLY_TICKET_SUCCESS: 'REPLY_TICKET_SUCCESS',
  REPLY_TICKET_ERROR: 'REPLY_TICKET_ERROR',

  createTicket: (data) => {
    return {
      type: actions.CREATE_TICKET_REQUEST,
      data
    };
  },
  updateTicket: (data) => {
    return {
      type: actions.UPDATE_TICKET_REQUEST,
      data
    };
  },
  replyTicket: (data) => {
    return {
      type: actions.REPLY_TICKET_REQUEST,
      data
    };
  },
  getAllTickets: (data) => {
    return {
      type: actions.GET_ALL_TICKETS_REQUEST,
      data
    };
  },
  getTicketById: (id) => {
    return {
      type: actions.GET_TICKET_DETAIL_REQUEST,
      id
    };
  }
};

const initState = {
  isLoading: false,
  list: [],
  error: null,
  metaData: {
    count: 0,
  },
  ticketDetailLoading: false,
  ticketDetail: null,
  ticketDetailError: null,
  createTicketLoading: false,
  createTicketSuccess: false,
  replyTicketLoading: false,
  replyTicketSuccess: false,
  updateTicketLoading: false,
  updateTicketSuccess: false
};

export default function userReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.GET_ALL_TICKETS_REQUEST:
      return {
        ...state, isLoading: true, error: null,
      };
    case actions.GET_ALL_TICKETS_SUCCESS:
      return {
        ...state, ...payload, isLoading: false, error: null,
      };
    case actions.GET_ALL_TICKET_ERROR:
      return {
        ...state, isLoading: false, list: [], ...payload,
      };
    case actions.GET_TICKET_DETAIL_REQUEST:
      return {
        ...state, ticketDetailLoading: true, ticketDetailError: null,
      };
    case actions.GET_TICKET_DETAIL_SUCCESS:
      return {
        ...state, ...payload, ticketDetailLoading: false, ticketDetailError: null,
      };
    case actions.GET_TICKET_DETAIL_ERROR:
      return {
        ...state, ticketDetailLoading: false, ...payload,
      };
    case actions.CREATE_TICKET_REQUEST:
      return {
        ...state, createTicketLoading: true, ticketDetailError: null,
      };
    case actions.CREATE_TICKET_SUCCESS:
      return {
        ...state, ...payload, createTicketLoading: false, ticketDetailError: null,
      };
    case actions.CREATE_TICKET_ERROR:
      return {
        ...state, createTicketLoading: false, ...payload,
      };
    case actions.UPDATE_TICKET_REQUEST:
      return {
        ...state, updateTicketLoading: true, ticketDetailError: null,
      };
    case actions.UPDATE_TICKET_SUCCESS:
      return {
        ...state, ...payload, updateTicketLoading: false, ticketDetailError: null,
      };
    case actions.UPDATE_TICKET_ERROR:
      return {
        ...state, updateTicketLoading: false, ...payload,
      };
    case actions.REPLY_TICKET_REQUEST:
      return {
        ...state, replyTicketLoading: true, ticketDetailError: null,
      };
    case actions.REPLY_TICKET_SUCCESS:
      return {
        ...state, ...payload, replyTicketLoading: false, ticketDetailError: null,
      };
    case actions.REPLY_TICKET_ERROR:
      return {
        ...state, replyTicketLoading: false, ...payload,
      };
    default:
      return state;
  }
}
