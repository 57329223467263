import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Layout, Button, Icon, Badge } from 'antd';
import { Debounce } from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';
import { ThemeProvider } from 'styled-components';
import LoadingBar from 'react-redux-loading-bar';
import { actions as authAction } from '../../redux/auth';
import { actions as appActions } from '../../redux/app';
import { actions as aduanActions } from '../../redux/tickets';
import Sidebar from '../Sidebar/Sidebar';
import Topbar from '../Topbar/Topbar';
import AppRouter from './AppRouter';
import { siteConfig, themeConfig } from '../../settings';
import themes from '../../settings/themes';
import AppHolder from './commonStyle';
import './global.css';

const { Content, Footer } = Layout;
const { logout } = authAction;
const { toggleAll } = appActions;


const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};

const btnStyles = { padding: 5 };
const iconStyles = { fontSize: '20px', color: '#DE0000' };
const styleButton = { fontSize: '12px', fontWeight: 'bold', color: '#DE0000' };

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

const App = ({ match: { url }, history, GetAllTickets, counterTickets }) => {
  const { height, width } = useWindowDimensions();
  const [displayMenu, setdisplayMenu] = useState(false);

  useEffect(() => {
    if (width < 426) setdisplayMenu(true);
    else setdisplayMenu(false);
  }, [width]);

  useEffect(() => {
    GetAllTickets({
      universal: 'status',
      universalValue: 'opened',
    });
  }, [GetAllTickets]);

  return (
    <ThemeProvider theme={themes[themeConfig.theme]}>
      <AppHolder>
        <Layout style={{ height }}>
          <Debounce time="1000" handler="onResize">
            <WindowResizeListener
              onResize={(windowSize) => toggleAll(
                windowSize.windowWidth,
                windowSize.windowHeight,
              )}
            />
          </Debounce>
          <LoadingBar style={{ zIndex: '9999', backgroundColor: 'rgba(0, 136, 255, 1)' }} />
          <Topbar url={url} displayButton={displayMenu} />
          <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
            {!displayMenu && (<Sidebar url={url} />)}
            <Layout
              className="isoContentMainLayout"
              style={{
                height: window.innerHeight,
              }}
            >
              <Content
                className="isomorphicContent"
                style={{
                  padding: '70px 0 0',
                  flexShrink: '0',
                  background: '#ffffff',
                  position: 'relative',
                }}
              >
                <AppRouter url={url} />
              </Content>
              <Footer
                style={{
                  background: '#ffffff',
                  textAlign: 'center',
                  borderTop: '1px solid #ededed',
                }}
              >
                {siteConfig.footerText}
                {
                  displayMenu && (

                    <div
                      style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        zIndex: 1000,
                        width: '100%',
                        borderTop: '2px solid #DE0000',
                        backgroundColor: '#f1f1f1',
                        padding: '10px 0px',
                        flex: 1,
                        flexDirection: 'row',
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          flexGrow: 0,
                          flexBasis: '20%',
                          paddingBottom: 10,
                        }}
                      >
                        <Button
                          type="link"
                          style={btnStyles}
                          onClick={() => history.push('/dashboard/listRaperdaRapdbd')}
                        >
                          <Icon
                            type="form"
                            style={iconStyles}
                          />
                          <div
                            style={styleButton}
                          >
                            RAPBD
                          </div>
                        </Button>
                        <Button
                          type="link"
                          style={btnStyles}
                          onClick={() => history.push('/dashboard/agenda')}
                        >
                          <Icon
                            type="unordered-list"
                            style={iconStyles}
                          />
                          <div
                            style={styleButton}
                          >
                            AGENDA
                          </div>
                        </Button>
                        <Button
                          type="link"
                          style={btnStyles}
                          onClick={() => history.push('/dashboard/listLaporan')}
                        >
                          <Icon
                            type="unordered-list"
                            style={iconStyles}
                          />
                          <div
                            style={styleButton}
                          >
                            LAPORAN
                          </div>
                        </Button>
                        {/* <Button
                          type="link"
                          style={btnStyles}
                          onClick={() => history.push('/dashboard/listPokir')}
                        >
                          <Icon
                            type="diff"
                            style={iconStyles}
                          />
                          <div
                            style={styleButton}
                          >
                            POKIR
                          </div>
                        </Button> */}
                        <Button
                          type="link"
                          style={btnStyles}
                          onClick={() => history.push('/dashboard/listTickets')}
                        >
                          <Badge
                            count={counterTickets}
                            style={{ backgroundColor: '#52c41a' }}
                          >
                            <Icon
                              type="customer-service"
                              style={iconStyles}
                            />
                          </Badge>
                          <div
                            style={styleButton}
                          >
                            ADUAN
                          </div>
                        </Button>
                        <Button
                          type="link"
                          style={btnStyles}
                          onClick={() => history.push('/dashboard/listRating')}
                        >
                          <Icon
                            type="pie-chart"
                            style={iconStyles}
                          />
                          <div
                            style={styleButton}
                          >
                            RATING
                          </div>
                        </Button>
                      </div>
                    </div>
                  )
                }
              </Footer>
            </Layout>
          </Layout>
        </Layout>
      </AppHolder>
    </ThemeProvider>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetAllTickets: (data) => dispatch(aduanActions.getAllTickets(data)),
    logout,
    toggleAll
  };
};

export default connect(
  (state) => ({
    auth: state.auth,
    height: state.app.height,
    counterTickets: state.tickets.metaData.count,
  }),
  mapDispatchToProps,
)(App);
