export const actions = {
  GET_ALL_RAPBD_REQUEST: 'GET_ALL_RAPBD_REQUEST',
  GET_ALL_RAPBD_SUCCESS: 'GET_ALL_RAPBD_SUCCESS',
  GET_ALL_RAPBD_ERROR: 'GET_ALL_RAPBD_ERROR',
  GET_ALL_RAPBD_RESET: 'GET_ALL_RAPBD_RESET',
  GET_RAPBD_DETAIL_REQUEST: 'GET_RAPBD_DETAIL_REQUEST',
  GET_RAPBD_DETAIL_SUCCESS: 'GET_RAPBD_DETAIL_SUCCESS',
  GET_RAPBD_DETAIL_ERROR: 'GET_RAPBD_DETAIL_ERROR',
  GET_RAPBD_DETAIL_RESET: 'GET_RAPBD_DETAIL_RESET',
  CREATE_RAPBD_REQUEST: 'CREATE_RAPBD_REQUEST',
  CREATE_RAPBD_SUCCESS: 'CREATE_RAPBD_SUCCESS',
  CREATE_RAPBD_ERROR: 'CREATE_RAPBD_ERROR',
  UPDATE_RAPBD_REQUEST: 'UPDATE_RAPBD_REQUEST',
  UPDATE_RAPBD_SUCCESS: 'UPDATE_RAPBD_SUCCESS',
  UPDATE_RAPBD_ERROR: 'UPDATE_RAPBD_ERROR',

  createRapdb: (data) => {
    return {
      type: actions.CREATE_RAPBD_REQUEST,
      data
    };
  },
  updateRapbd: (data) => {
    return {
      type: actions.UPDATE_RAPBD_REQUEST,
      data
    };
  },
  getAllRapbd: (data) => {
    return {
      type: actions.GET_ALL_RAPBD_REQUEST,
      data,
    };
  },
  getRapbdById: (id) => {
    return {
      type: actions.GET_RAPBD_DETAIL_REQUEST,
      id
    };
  },
  reset: () => {
    return {
      type: actions.GET_ALL_RAPBD_RESET,
    };
  },
  resetDetail: () => {
    return {
      type: actions.GET_RAPBD_DETAIL_RESET,
    };
  },
};

const initState = {
  isLoading: false,
  list: [],
  error: null,
  metaData: {
    count: 0,
    page: 1,
    limit: 10,
  },
  rapbdDetailLoading: false,
  rapbdDetail: null,
  rapbdDetailError: null,
  createRapbdLoading: false,
  createRapbdSuccess: false,
  updateRapbdLoading: false,
  updateRapbdSuccess: false
};

export default function userReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.GET_ALL_RAPBD_REQUEST:
      return {
        ...state, isLoading: true, error: null,
      };
    case actions.GET_ALL_RAPBD_SUCCESS:
      return {
        ...state, ...payload, isLoading: false, error: null,
      };
    case actions.GET_ALL_RAPBD_ERROR:
      return {
        ...state, isLoading: false, list: [], ...payload,
      };
    case actions.GET_ALL_RAPBD_RESET:
      return {
        ...state,
        isLoading: false,
        list: [],
        count: 0,
        page: 0,
        error: null,
        limit: 10,
        ...payload,
      };
    case actions.GET_RAPBD_DETAIL_REQUEST:
      return {
        ...state, rapbdDetailLoading: true, rapbdDetailError: null,
      };
    case actions.GET_RAPBD_DETAIL_SUCCESS:
      return {
        ...state, ...payload, rapbdDetailLoading: false, rapbdDetailError: null,
      };
    case actions.GET_RAPBD_DETAIL_ERROR:
      return {
        ...state, rapbdDetailLoading: false, ...payload,
      };
    case actions.CREATE_RAPBD_REQUEST:
      return {
        ...state, createRapbdLoading: true, rapbdDetailError: null,
      };
    case actions.CREATE_RAPBD_SUCCESS:
      return {
        ...state, ...payload, createRapbdLoading: false, rapbdDetailError: null,
      };
    case actions.CREATE_RAPBD_ERROR:
      return {
        ...state, createRapbdLoading: false, ...payload,
      };
    case actions.UPDATE_RAPBD_REQUEST:
      return {
        ...state, createRapbdLoading: true, rapbdDetailError: null,
      };
    case actions.UPDATE_RAPBD_SUCCESS:
      return {
        ...state, ...payload, createRapbdLoading: false, rapbdDetailError: null,
      };
    case actions.UPDATE_RAPBD_ERROR:
      return {
        ...state, createRapbdLoading: false, ...payload,
      };
    default:
      return state;
  }
}
