export const actions = {
  LEGISLATOR_LIST_REQUEST: 'LEGISLATOR_LIST_REQUEST',
  LEGISLATOR_LIST_SUCCESS: 'LEGISLATOR_LIST_SUCCESS',
  LEGISLATOR_LIST_ERROR: 'LEGISLATOR_LIST_ERROR',

  getLegislator: (data) => {
    return {
      type: actions.LEGISLATOR_LIST_REQUEST,
      data,
    };
  }
};

const initState = {
  isLoading: false,
  list: [],
  error: null,
  detail: null,
};

export default function legislatorReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LEGISLATOR_LIST_REQUEST:
      return {
        ...state, isLoading: true, error: null,
      };
    case actions.LEGISLATOR_LIST_SUCCESS:
      return {
        ...state, ...payload, isLoading: false, error: null,
      };
    case actions.LEGISLATOR_LIST_ERROR:
      return {
        ...state, isLoading: false, list: [], detail: null, ...payload,
      };
    default:
      return state;
  }
}
