/* eslint-disable no-async-promise-executor */
import axios from '../plugins/axios';
import { getLegislatorId } from '../utils/helpers';

export function apiGetAllRapbd(payload) {
  let url = `/rapbd?legislator_id=${getLegislatorId()}&limit=${payload.limit}&page=${payload.page}`;
  if (payload.universal && payload.universalValue) url += `&${payload.universal}=${payload.universalValue}`;
  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url,
    }).then((res) => {
      if (res.status === 200) resolve(res.data);
    }).catch((err) => reject(err));
  });
}

export function apiGetRapbdById(id) {
  return new Promise(async (resolve, reject) => {
    const url = `/rapbd/${id}`;
    try {
      const response = await axios({
        method: 'GET',
        url
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiCreateRapbd(data) {
  return new Promise(async (resolve, reject) => {
    const url = '/rapbd';
    try {
      const response = await axios({
        method: 'POST',
        url,
        data
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiUpdateRapbd(id, params) {
  return new Promise(async (resolve, reject) => {
    const url = `/rapbd/${id}`;
    try {
      const response = await axios({
        method: 'PUT',
        url,
        data: params
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiAddThreadRapbd(data, id) {
  return new Promise(async (resolve, reject) => {
    const url = `/rapbd/${id}/thread`;
    try {
      const response = await axios({
        method: 'POST',
        url,
        data
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiDeleteRapbdById(id) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'DELETE',
      url: `/rapbd/${id}`,
    }).then((res) => {
      if (res.status !== 200) reject(res.response.data);
      else resolve(res.data);
    }).catch((error) => {
      reject(error);
    });
  });
}
