import axios from '../plugins/axios';

const test = () => {
  return null;
};

const apiGetCity = (payload) => {
  return new Promise((resolve, reject) => {
    let url = `${process.env.REACT_APP_AUTH_URL}/city?`;
    if (payload.provinceId) url += `&province_id=${payload.provinceId}`;
    if (payload.provinceCode) url += `&province_code=${payload.provinceCode}`;
    axios({
      method: 'GET',
      url,
    }).then((res) => {
      if (res.status !== 200) reject(res.response.data);
      else resolve(res.data);
    }).catch((error) => {
      reject(error);
    });
  });
};

export {
  test,
  apiGetCity,
};
