import {
  all, takeEvery, put, fork, call
} from 'redux-saga/effects';
import { notification } from '../components';
import { actions } from '../redux/tickets';
import {
  apiGetAllTickets,
  apiGetTicketById,
  apiCreateTicket,
  apiUpdateTicket,
  apiReplyTicketById
} from '../api/api-tickets';

export function* getAllTickets() {
  yield takeEvery(actions.GET_ALL_TICKETS_REQUEST, function* (payload) {
    try {
      const response = yield call(apiGetAllTickets, payload.data);
      yield put({
        type: actions.GET_ALL_TICKETS_SUCCESS,
        payload: {
          list: response.data.rows,
          metaData: {
            count: response.data.count,
          }
        }
      });
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }
      if (error.message) {
        notification('error', 'Something went wrong', error.message);
      } else {
        notification('error', 'Something went wrong', JSON.stringify(error));
      }
      yield put({
        type: actions.GET_ALL_TICKET_ERROR,
        payload: {
          errorAuth: errorResponse,
        },
      });
    }
  });
}

export function* getTicketById() {
  yield takeEvery(actions.GET_TICKET_DETAIL_REQUEST, function* (payload) {
    try {
      const response = yield call(apiGetTicketById, payload.id);
      if (response.status === 200) {
        yield put({
          type: actions.GET_TICKET_DETAIL_SUCCESS,
          payload: {
            ticketDetail: response.data.data
          }
        });
        return;
      }
      yield put({
        type: actions.GET_TICKET_DETAIL_ERROR,
        payload: {
          ticketDetailError: `${response.status} unknown error`,
        },
      });
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }
      if (error.message) {
        notification('error', 'Something went wrong', error.message);
      } else {
        notification('error', 'Something went wrong', JSON.stringify(error));
      }
      yield put({
        type: actions.GET_TICKET_DETAIL_ERROR,
        payload: {
          errorAuth: errorResponse,
        },
      });
    }
  });
}

export function* replyTicketById() {
  yield takeEvery(actions.REPLY_TICKET_REQUEST, function* (payload) {
    try {
      const response = yield call(apiReplyTicketById, payload.data.id, payload.data.params);
      yield put({
        type: actions.REPLY_TICKET_SUCCESS,
        payload: {
          replyTicketSuccess: true,
          ticketDetail: response.data.data
        }
      });
      return;
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }
      if (error.message) {
        notification('error', 'Something went wrong', error.message);
      } else {
        notification('error', 'Something went wrong', JSON.stringify(error));
      }
      yield put({
        type: actions.REPLY_TICKET_ERROR,
        payload: {
          errorTicket: errorResponse,
        },
      });
    }
  });
}

export function* createTicket() {
  yield takeEvery(actions.CREATE_TICKET_REQUEST, function* (payload) {
    try {
      const response = yield call(apiCreateTicket, payload.data);
      if (response.status === 200) {
        yield put({
          type: actions.CREATE_RAPBD_SUCCESS,
          payload: {
            createTicketSuccess: true
          }
        });
        return;
      }
      yield put({
        type: actions.CREATE_TICKET_ERROR,
        payload: {
          createTicketError: `${response.status} unknown error`,
        },
      });
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }
      if (error.message) {
        notification('error', 'Something went wrong', error.message);
      } else {
        notification('error', 'Something went wrong', JSON.stringify(error));
      }
      yield put({
        type: actions.CREATE_TICKET_ERROR,
        payload: {
          errorTicket: errorResponse,
        },
      });
    }
  });
}

export function* updateTicket() {
  yield takeEvery(actions.UPDATE_TICKET_REQUEST, function* (payload) {
    try {
      const response = yield call(apiUpdateTicket, payload.data.id, payload.data.params);
      if (response.status === 200) {
        yield put({
          type: actions.UPDATE_TICKET_SUCCESS,
          payload: {
            updateTicketSuccess: true
          }
        });
        return;
      }
      yield put({
        type: actions.UPDATE_TICKET_ERROR,
        payload: {
          updateTicketError: `${response.status} unknown error`,
        },
      });
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }
      if (error.message) {
        notification('error', 'Something went wrong', error.message);
      } else {
        notification('error', 'Something went wrong', JSON.stringify(error));
      }
      yield put({
        type: actions.UPDATE_TICKET_ERROR,
        payload: {
          errorAuth: errorResponse,
        },
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getAllTickets),
    fork(getTicketById),
    fork(createTicket),
    fork(updateTicket),
    fork(replyTicketById)
  ]);
}
