import {
  all, takeEvery, put, fork, call
} from 'redux-saga/effects';
import { notification } from '../components';
import { actions } from '../redux/legislator';
import { apiGetLegislator } from '../api/api-legislator';

export function* getLegislator() {
  yield takeEvery(actions.LEGISLATOR_LIST_REQUEST, function* (payload) {
    try {
      const response = yield call(apiGetLegislator, payload.data);
      if (response.status === 200) {
        yield put({
          type: actions.LEGISLATOR_LIST_SUCCESS,
          payload: {
            list: response.data.data.rows,
            count: response.data.data.count,
          }
        });
        return;
      }
      yield put({
        type: actions.LEGISLATOR_LIST_ERROR,
        payload: {
          message: `${response.status} unknown error`,
        },
      });
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }
      if (error.message) {
        notification('error', 'Something went wrong', error.message);
      } else {
        notification('error', 'Something went wrong', JSON.stringify(error));
      }
      yield put({
        type: actions.LEGISLATOR_LIST_ERROR,
        payload: {
          errorAuth: errorResponse,
        },
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getLegislator)
  ]);
}
