export const actions = {
  GET_PROVINCE_REQUEST: 'GET_PROVINCE_REQUEST',
  GET_PROVINCE_SUCCESS: 'GET_PROVINCE_SUCCESS',
  GET_PROVINCE_ERROR: 'GET_PROVINCE_ERROR',

  getAllProvince: () => {
    return {
      type: actions.GET_PROVINCE_REQUEST,
    };
  },
};

const initState = {
  isLoading: false,
  list: [],
  error: null,
  metaData: {
    count: 0,
  },
};

export default function userReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.GET_PROVINCE_REQUEST:
      return {
        ...state, isLoading: true, error: null,
      };
    case actions.GET_PROVINCE_SUCCESS:
      return {
        ...state, ...payload, isLoading: false, error: null,
      };
    case actions.GET_PROVINCE_ERROR:
      return {
        ...state, isLoading: false, list: [], ...payload,
      };

    default:
      return state;
  }
}
