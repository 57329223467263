import {
  all, takeEvery, put, fork, call
} from 'redux-saga/effects';
import { notification } from '../components';
import { actions } from '../redux/profile';
import { apiGetLegislatorData } from '../api/api-profile';

export function* getLegislatorData() {
  yield takeEvery(actions.PROFILE_REQUEST, function* (payload) {
    try {
      const response = yield call(apiGetLegislatorData, payload.data);
      if (response) {
        yield put({
          type: actions.PROFILE_SUCCESS,
          payload: {
            success: response.data,
          }
        });
      }
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }
      if (error.message) {
        notification('error', 'Something went wrong', error.message);
      } else {
        notification('error', 'Something went wrong', JSON.stringify(error));
      }
      yield put({
        type: actions.PROFILE_ERROR,
        payload: {
          errorAuth: errorResponse,
        },
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getLegislatorData),
  ]);
}
