import {
  all, takeEvery, put, fork, call
} from 'redux-saga/effects';
import { actions } from '../redux/jadwalAgenda';
import { notification } from '../components';
import { codeStatus } from '../utils/constant';
import {
  apiGetJadwalAgenda,
  apiGetAgendaById,
  apiCreateAgenda,
  apiUpdateAgenda,
  apiDeleteAgenda
} from '../api/api-jadwal-agenda';

export function* searchJadwalAgenda() {
  yield takeEvery(actions.JADWAL_AGENDA_REQUEST, function* (payload) {
    try {
      const response = yield call(apiGetJadwalAgenda, payload.data);
      if (response.status === codeStatus.SUCCESS) {
        yield put({
          type: actions.JADWAL_AGENDA_SUCCESS,
          payload: {
            list: response.data.data.rows,
            count: response.data.data.count,
          }
        });
        return;
      }

      const errorMessage = response.data.message || 'Unknown Error';
      yield put({
        type: actions.JADWAL_AGENDA_ERROR,
        payload: {
          message: errorMessage
        }
      });

      notification('error', errorMessage);
    } catch (error) {
      let errorResponse = { message: error.message };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }

      yield put({
        type: actions.JADWAL_AGENDA_ERROR,
        payload: errorResponse
      });

      notification('error', 'Something went wrong', errorResponse.message);
    }
  });
}

export function* getJadwalAgendaById() {
  yield takeEvery(actions.JADWAL_AGENDA_DETAIL_REQUEST, function* (payload) {
    try {
      const response = yield call(apiGetAgendaById, payload.data);
      if (response.status === codeStatus.SUCCESS) {
        yield put({
          type: actions.JADWAL_AGENDA_DETAIL_SUCCESS,
          payload: {
            detail: response.data.data
          }
        });
        return;
      }

      yield put({
        type: actions.JADWAL_AGENDA_DETAIL_ERROR,
        payload: {
          message: `${response.status} unknown error`,
        },
      });

      notification('error', response.data.message);
    } catch (error) {
      let errorResponse = { message: error.message };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }

      yield put({
        type: actions.JADWAL_AGENDA_DETAIL_ERROR,
        payload: errorResponse
      });

      notification('error', 'Something went wrong', errorResponse.message);
    }
  });
}

export function* createJadwalAgenda() {
  yield takeEvery(actions.JADWAL_AGENDA_CREATE_REQUEST, function* (payload) {
    try {
      const response = yield call(apiCreateAgenda, payload.data.params);
      if (response.status === codeStatus.SUCCESS) {
        yield put({
          type: actions.JADWAL_AGENDA_CREATE_SUCCESS,
          payload: response.data
        });

        notification('success', 'Successfully create new agenda!');
        payload.data.history.goBack();
        payload.data.resetFields();
        return;
      }

      yield put({
        type: actions.JADWAL_AGENDA_CREATE_ERROR,
        payload: {
          message: response.data.message
        },
      });

      notification('error', response.data.message);
    } catch (error) {
      let errorResponse = { message: error.message };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }

      notification('error', 'Something went wrong', errorResponse.message);
      yield put({
        type: actions.JADWAL_AGENDA_CREATE_ERROR,
        payload: {
          error: errorResponse,
        },
      });
    }
  });
}

export function* updateJadwalAgenda() {
  yield takeEvery(actions.JADWAL_AGENDA_UPDATE_REQUEST, function* (payload) {
    try {
      const response = yield call(apiUpdateAgenda, payload.data.id, payload.data.params);
      if (response.status === codeStatus.SUCCESS) {
        yield put({
          type: actions.JADWAL_AGENDA_UPDATE_SUCCESS,
          payload: response.data
        });

        notification('success', 'Successfully update agenda!');
        return;
      }

      yield put({
        type: actions.JADWAL_AGENDA_UPDATE_ERROR,
        payload: {
          message: `${response.status} unknown error`,
        },
      });
      notification('error', response.data.message);
    } catch (error) {
      let errorResponse = { message: error.message };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }

      yield put({
        type: actions.JADWAL_AGENDA_UPDATE_ERROR,
        payload: {
          error: errorResponse,
        },
      });

      notification('error', 'Something went wrong', errorResponse.message);
    }
  });
}

export function* deleteJadwalAgenda() {
  yield takeEvery(actions.JADWAL_AGENDA_DELETE_REQUEST, function* (payload) {
    try {
      const response = yield call(apiDeleteAgenda, payload.data);
      if (response.status === codeStatus.SUCCESS) {
        yield put({
          type: actions.JADWAL_AGENDA_DELETE_SUCCESS,
          payload: response.data
        });

        notification('success', 'Successfully delete agenda!');
        return;
      }

      yield put({
        type: actions.JADWAL_AGENDA_UPDATE_ERROR,
        payload: {
          message: `${response.status} unknown error`,
        },
      });

      notification('error', response.data.message);
    } catch (error) {
      let errorResponse = { message: error.message };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }

      yield put({
        type: actions.JADWAL_AGENDA_UPDATE_ERROR,
        payload: {
          error: errorResponse,
        },
      });

      notification('error', 'Something went wrong', errorResponse.message);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(searchJadwalAgenda),
    fork(getJadwalAgendaById),
    fork(createJadwalAgenda),
    fork(updateJadwalAgenda),
    fork(deleteJadwalAgenda)
  ]);
}
