import {
  all, takeEvery, put, fork, call, takeLatest,
} from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { notification } from '../components';
import { actions } from '../redux/auth';
import { apiPostLogin } from '../api/api-auth';
import { apiGetUserMe } from '../api/api-user';

import {
  URL_ROOT, URL_LOGIN,
} from '../page-url';

import {
  clearTokenInLocalStorage,
  clearLocalStorage,
} from '../utils/helpers';


export function* logout() {
  yield takeLatest(actions.AUTH_LOGOUT, function* () {
    clearTokenInLocalStorage();
    clearLocalStorage({ key: 'cws-usr' });
    clearLocalStorage({ key: 'tms-allow' });
    localStorage.removeItem('is-refreshed');
    const bdsmRef = window.location.replace('/');
    yield put({
      bdsmRef,
    });
  });
}

export function* loginRequest() {
  yield takeLatest(actions.AUTH_LOGIN_REQUEST, function* ({ payload }) {
    try {
      const response = yield call(apiPostLogin, payload);
      if (response) {
        const {
          role,
          legislator_id
        } = response.user;
        if (role && role === 3 && legislator_id) {
          yield put({
            type: actions.AUTH_LOGIN_SUCCESS,
            payload: {
              user: response.user,
              token: response.token,
              refresh_token: response.refresh_token,
              errorAuth: null,
            },
          });
          yield put(push(URL_ROOT()));
        } else {
          clearTokenInLocalStorage();
          notification('error', 'Forbidden Access', 'Only legislator can login, please contact administrator.');
          yield put({
            type: actions.AUTH_LOGIN_ERROR,
            payload: {
              errorAuth: 'no access',
            },
          });
        }
      } else {
        yield put({
          type: actions.AUTH_LOGIN_ERROR,
          payload: {
            errorAuth: 'something went wrong',
          },
        });
      }
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      } else {
        errorResponse = {
          status: '500',
          message: 'SSO Server Error',
        };
      }
      notification('error', `${errorResponse.error_code} - ${errorResponse.message}`);
      yield put({
        type: actions.AUTH_LOGIN_ERROR,
        payload: {
          errorAuth: errorResponse,
        },
      });
    }
  });
}

export function* userRequest() {
  yield takeEvery(actions.AUTH_USER_REQUEST, function* () {
    try {
      const response = yield call(apiGetUserMe);
      if (response.status === 200) {
        const findTms = response.data.data.find((el) => el.app.code === 'TMS');
        yield put({
          type: actions.AUTH_USER_SUCCESS,
          payload: {
            user: findTms,
          },
        });
        return;
      }
      yield put({
        type: actions.AUTH_USER_ERROR,
        payload: {
          message: `${response.status} unknown error`,
        },
      });
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }
      yield put({
        type: actions.AUTH_USER_ERROR,
        payload: {
          errorAuth: errorResponse,
        },
      });
    }
  });
}
// export function* userSuccess() {
//   yield takeEvery(actions.AUTH_USER_SUCCESS, function* () {});
// }
export function* userError() {
  yield takeEvery(actions.AUTH_USER_ERROR, function* () {
    yield put(push(URL_LOGIN()));
  });
}

export default function* rootSaga() {
  yield all([
    // fork(checkAuthorization),
    fork(loginRequest),
    // fork(loginSuccess),
    // fork(loginError),
    fork(userRequest),
    fork(userError),
    fork(logout),
  ]);
}
