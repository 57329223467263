/* eslint-disable no-async-promise-executor */
import axios from '../plugins/axios';
import { getLegislatorId } from '../utils/helpers';

export function apiGetAllTickets(payload) {
  let url = `/tickets?legislator_id=${getLegislatorId()}`;
  if (payload.universal && payload.universalValue) url += `&${payload.universal}=${payload.universalValue}`;
  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url,
    }).then((res) => {
      if (res.status === 200) resolve(res.data);
    }).catch((err) => reject(err));
  });
}

export function apiGetTicketById(id) {
  return new Promise(async (resolve, reject) => {
    const url = `/tickets/${id}`;
    try {
      const response = await axios({
        method: 'GET',
        url
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiReplyTicketById(id, params) {
  return new Promise(async (resolve, reject) => {
    const url = `/tickets/${id}/reply`;
    try {
      const response = await axios({
        method: 'POST',
        url,
        data: params
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiCreateTicket(data) {
  return new Promise(async (resolve, reject) => {
    const url = '/ticket';
    try {
      const response = await axios({
        method: 'POST',
        url,
        data
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function apiUpdateTicket(id, params) {
  return new Promise(async (resolve, reject) => {
    const url = `/tickets/${id}`;
    try {
      const response = await axios({
        method: 'PUT',
        url,
        data: params
      });
      if (response.status !== 200) {
        reject(response.response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}
