import {
  all, takeEvery, put, fork, call
} from 'redux-saga/effects';
import { notification } from '../components';
import { actions } from '../redux/rapbd';
import {
  apiGetAllRapbd,
  apiGetRapbdById,
  apiCreateRapbd,
  apiUpdateRapbd
} from '../api/api-rapbd';

export function* getAllRapbd() {
  yield takeEvery(actions.GET_ALL_RAPBD_REQUEST, function* (payload) {
    try {
      const response = yield call(apiGetAllRapbd, payload.data);
      yield put({
        type: actions.GET_ALL_RAPBD_SUCCESS,
        payload: {
          list: response.data.rows,
          metaData: {
            count: response.data.count,
            page: payload.data.page,
            limit: payload.data.limit,
          }
        }
      });
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }
      if (error.message) {
        notification('error', 'Something went wrong', error.message);
      } else {
        notification('error', 'Something went wrong', JSON.stringify(error));
      }
      yield put({
        type: actions.GET_ALL_RAPBD_ERROR,
        payload: {
          errorAuth: errorResponse,
        },
      });
    }
  });
}

export function* getRapbdById() {
  yield takeEvery(actions.GET_RAPBD_DETAIL_REQUEST, function* (payload) {
    try {
      const response = yield call(apiGetRapbdById, payload.id);
      if (response.status === 200) {
        yield put({
          type: actions.GET_RAPBD_DETAIL_SUCCESS,
          payload: {
            rapbdDetail: response.data.data
          }
        });
        return;
      }
      yield put({
        type: actions.GET_RAPBD_DETAIL_ERROR,
        payload: {
          rapbdDetailError: `${response.status} unknown error`,
        },
      });
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }
      if (error.message) {
        notification('error', 'Something went wrong', error.message);
      } else {
        notification('error', 'Something went wrong', JSON.stringify(error));
      }
      yield put({
        type: actions.GET_RAPBD_DETAIL_ERROR,
        payload: {
          errorAuth: errorResponse,
        },
      });
    }
  });
}

export function* createRapbd() {
  yield takeEvery(actions.CREATE_RAPBD_REQUEST, function* (payload) {
    try {
      const response = yield call(apiCreateRapbd, payload.data);
      if (response.status === 200) {
        yield put({
          type: actions.CREATE_RAPBD_SUCCESS,
          payload: {
            createRapbdSuccess: true
          }
        });
        return;
      }
      yield put({
        type: actions.CREATE_RAPBD_ERROR,
        payload: {
          rapbdDetailError: `${response.status} unknown error`,
        },
      });
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }
      if (error.message) {
        notification('error', 'Something went wrong', error.message);
      } else {
        notification('error', 'Something went wrong', JSON.stringify(error));
      }
      yield put({
        type: actions.CREATE_RAPBD_ERROR,
        payload: {
          errorAuth: errorResponse,
        },
      });
    }
  });
}

export function* updateRapbd() {
  yield takeEvery(actions.UPDATE_RAPBD_REQUEST, function* (payload) {
    try {
      const response = yield call(apiUpdateRapbd, payload.data.id, payload.data.params);
      if (response.status === 200) {
        yield put({
          type: actions.UPDATE_RAPBD_SUCCESS,
          payload: {
            updateRapbdSuccess: true
          }
        });
        return;
      }
      yield put({
        type: actions.UPDATE_RAPBD_ERROR,
        payload: {
          rapbdDetailError: `${response.status} unknown error`,
        },
      });
    } catch (error) {
      let errorResponse = { message: '' };
      if (error.response && error.response.data) {
        errorResponse = error.response.data;
      }
      if (error.message) {
        notification('error', 'Something went wrong', error.message);
      } else {
        notification('error', 'Something went wrong', JSON.stringify(error));
      }
      yield put({
        type: actions.UPDATE_RAPBD_ERROR,
        payload: {
          errorAuth: errorResponse,
        },
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getAllRapbd),
    fork(getRapbdById),
    fork(createRapbd),
    fork(updateRapbd)
  ]);
}
