import { all } from 'redux-saga/effects';
import authSagas from './authSagas';
import rapbdSagas from './rapbdSagas';
import pokirSagas from './pokirSagas';
import forgotPassSagas from './account/forgotPassSagas';
import jadwalAgendaSagas from './jadwalAgendaSagas';
import legislatorSagas from './legislatorSagas';
import provinceSagas from './provinceSagas';
import citySagas from './citySagas';
import villageSgas from './villageSagas';
import districtSagas from './districtSagas';
import ticketsSagas from './ticketsSagas';
import laporanSagas from './laporanSagas';
import ratingSagas from './ratingSagas';
import profileSagas from './profileSagas';

export default function* rootSaga() {
  yield all([
    authSagas(),
    forgotPassSagas(),
    rapbdSagas(),
    jadwalAgendaSagas(),
    legislatorSagas(),
    pokirSagas(),
    provinceSagas(),
    citySagas(),
    villageSgas(),
    districtSagas(),
    ticketsSagas(),
    laporanSagas(),
    ratingSagas(),
    profileSagas(),
  ]);
}
