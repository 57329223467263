import axios from '../plugins/axios';
import { getLegislatorId } from '../utils/helpers';

const apiGetAllLaporan = (payload) => {
  return new Promise((resolve, reject) => {
    let url = `${process.env.REACT_APP_AUTH_URL}/laporan_kegiatan?legislator_id=${getLegislatorId()}&limit=${payload.limit}&page=${payload.page}`;
    if (payload.universal && payload.universalValue) url += `&${payload.universal}=${payload.universalValue}`;
    axios({
      method: 'GET',
      url,
    }).then((res) => {
      if (res.status !== 200) reject(res.response.data);
      else resolve(res.data);
    }).catch((error) => reject(error));
  });
};

const apiGetLaporanById = (id) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_AUTH_URL}/laporan_kegiatan/${id}`,
    }).then((res) => {
      if (res.status !== 200) reject(res.response.data);
      else resolve(res.data);
    }).catch((error) => {
      reject(error);
    });
  });
};

const apiCreateLaporan = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_AUTH_URL}/laporan_kegiatan`,
      data,
    }).then((res) => {
      if (res.status !== 200) reject(res.response);
      else resolve(res);
    }).catch((error) => {
      reject(error);
    });
  });
};

const apiEditLaporan = (data, id) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'PUT',
      url: `${process.env.REACT_APP_AUTH_URL}/laporan_kegiatan/${id}`,
      data,
    }).then((res) => {
      if (res.status !== 200) reject(res.response);
      else resolve(res);
    }).catch((error) => {
      reject(error);
    });
  });
};

const apiDeleteLaporanById = (id) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'DELETE',
      url: `${process.env.REACT_APP_AUTH_URL}/laporan_kegiatan/${id}`,
    }).then((res) => {
      if (res.status !== 200) reject(res.response.data);
      else resolve(res.data);
    }).catch((error) => {
      reject(error);
    });
  });
};

export {
  apiGetAllLaporan,
  apiGetLaporanById,
  apiCreateLaporan,
  apiEditLaporan,
  apiDeleteLaporanById,
};
